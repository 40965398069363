import common from '@mixins/common';
export default {
  mixins: [common],
  data() {
    return {
      headerTitle: '填写日报',
      form: {}
    };
  },
  mounted() {},
  methods: {
    onSubmit() {}
  }
};