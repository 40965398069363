var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e(), _c('div', {
          staticClass: "cyui-driections-navbar"
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', [_c('div', {
          staticClass: "info-wrapper"
        }, [_c('div', {
          staticClass: "info-card"
        }, [_c('div', {
          staticClass: "info-card-header"
        }, [_vm._v("房源信息")]), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("总房源数: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("委托房源数: 8")])], 1), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("可运营房源数: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("在住房数: 8")])], 1), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("自用房数: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("维修房数: 8")])], 1)], 1), _c('div', {
          staticClass: "info-card mt10"
        }, [_c('div', {
          staticClass: "info-card-header"
        }, [_vm._v("运营数据")]), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("在住房数: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("日租房数: 8")])], 1), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("已预订房数: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("空房数: 8")])], 1), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("出租率: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("月均值: 8")])], 1)], 1), _c('div', {
          staticClass: "info-card mt10"
        }, [_c('div', {
          staticClass: "info-card-header"
        }, [_vm._v("客户数据")]), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "24"
          }
        }, [_vm._v("新增企业微信客户: 8")])], 1), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "24"
          }
        }, [_vm._v("当日来访数: ")]), _c('van-col', {
          attrs: {
            "span": "24"
          }
        }, [_vm._v("自访: 10 ")]), _c('van-col', {
          attrs: {
            "span": "24"
          }
        }, [_vm._v("贝壳: 8")]), _c('van-col', {
          attrs: {
            "span": "24"
          }
        }, [_vm._v("企业客户: 8")])], 1), _c('van-row', {
          staticClass: "mt10 pb5 border-bottom"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("月度来访数: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("年度来访数: 8")])], 1), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("当日签约数: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("月度签约数: 8")])], 1), _c('van-row', {
          staticClass: "mt10 pb5 border-bottom"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("年度签约数: 8")])], 1), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("当日退房数: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("月度退房数: 8")])], 1), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("年度退房数: 8")])], 1)], 1), _c('div', {
          staticClass: "info-card mt10"
        }, [_c('div', {
          staticClass: "info-card-header"
        }, [_vm._v("客户画像")]), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("新签客户: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("预定客户: 8")])], 1), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("未成交客户: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("退订客户: 8")])], 1)], 1), _c('div', {
          staticClass: "info-card mt10"
        }, [_c('div', {
          staticClass: "info-card-header"
        }, [_vm._v("团队业绩")]), _c('van-row', {
          staticClass: "mt10 pb5 border-bottom"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("总目标: 8")])], 1), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("当日销冠: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("周销冠: 8")])], 1), _c('van-row', {
          staticClass: "mt10 pb5 border-bottom"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("月销冠: /")])], 1), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("当日末尾: 8")]), _c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("周末尾: 8")])], 1), _c('van-row', {
          staticClass: "mt10"
        }, [_c('van-col', {
          attrs: {
            "span": "12"
          }
        }, [_vm._v("月末尾: /")])], 1)], 1), _c('div', {
          staticClass: "info-card mt10"
        }, [_c('div', {
          staticClass: "info-card-header"
        }, [_vm._v("当日工作重点")]), _c('van-form', {
          on: {
            "submit": _vm.onSubmit
          }
        }, [_c('van-field', {
          staticClass: "my-textarea",
          attrs: {
            "label": "运营部",
            "placeholder": "请输入",
            "type": "textarea",
            "rows": "3",
            "autosize": "",
            "maxlength": "200",
            "show-word-limit": "",
            "required": "",
            "autofocus": ""
          },
          model: {
            value: _vm.form.yunying,
            callback: function ($$v) {
              _vm.$set(_vm.form, "yunying", $$v);
            },
            expression: "form.yunying"
          }
        }), _c('van-field', {
          staticClass: "my-textarea",
          attrs: {
            "label": "财务部",
            "placeholder": "请输入",
            "type": "textarea",
            "rows": "3",
            "autosize": "",
            "maxlength": "200",
            "show-word-limit": "",
            "required": "",
            "autofocus": ""
          },
          model: {
            value: _vm.form.yunying,
            callback: function ($$v) {
              _vm.$set(_vm.form, "yunying", $$v);
            },
            expression: "form.yunying"
          }
        }), _c('van-field', {
          staticClass: "my-textarea",
          attrs: {
            "label": "服务管理部",
            "placeholder": "请输入",
            "type": "textarea",
            "rows": "3",
            "autosize": "",
            "maxlength": "200",
            "show-word-limit": "",
            "required": "",
            "autofocus": ""
          },
          model: {
            value: _vm.form.yunying,
            callback: function ($$v) {
              _vm.$set(_vm.form, "yunying", $$v);
            },
            expression: "form.yunying"
          }
        })], 1)], 1), _c('div', {
          staticClass: "info-card mt10"
        }, [_c('div', {
          staticClass: "info-card-header"
        }, [_vm._v("需公司解决问题")]), _c('van-form', {
          on: {
            "submit": _vm.onSubmit
          }
        }, [_c('van-field', {
          staticClass: "my-textarea",
          attrs: {
            "placeholder": "请输入",
            "type": "textarea",
            "rows": "3",
            "autosize": "",
            "maxlength": "200",
            "show-word-limit": "",
            "required": "",
            "autofocus": ""
          },
          model: {
            value: _vm.form.yunying,
            callback: function ($$v) {
              _vm.$set(_vm.form, "yunying", $$v);
            },
            expression: "form.yunying"
          }
        })], 1)], 1)]), _c('div', {
          staticStyle: {
            "margin": "16px"
          }
        }, [_c('van-button', {
          attrs: {
            "round": "",
            "block": "",
            "type": "info",
            "color": "#d1a36f",
            "native-type": "submit"
          }
        }, [_vm._v("提交")])], 1)])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };